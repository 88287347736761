<template>
  <div v-if="visible">
    <div class="loader">
      <span class="icon-spinner3"></span>
      <span v-if="animatedNumber != null" class="text">
        {{ animatedNumber }}%
      </span>
    </div>
    <div id="loader-background" class="loader-background"></div>
  </div>
</template>

<script>
import AnimatedNumber from "../helpers/AnimatedNumber";

export default {
  name: "Loader",
  props: {
    visible: Boolean,
    number: Number,
  },
  data() {
    return {
      animatedNumber: null,
      animator: null,
    };
  },
  created() {
    this.animator = new AnimatedNumber(this.updateNumber.bind(this));
    this.animator.startFrom(this.number || 0);
  },
  watch: {
    number: function (val) {
      if (val >= 0) {
        this.animator.animate(val);
      }
    },
  },
  methods: {
    updateNumber(number) {
      this.animatedNumber = number;
    },
  },
};
</script>

<style lang="less" scoped>
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 60px;
  background-color: var(--bkg-loading-color);
  border-radius: var(--border-radius);
  text-align: center;
  color: var(--text-color);
  margin-left: -30px;
  margin-top: -30px;
  border: 1px solid var(--border-color);
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-spinner3 {
    font-size: 30px;
    line-height: 30px;
    transform-origin: 50% 50%;
    transform: rotate(0deg);
    display: block;
    animation: loading 1s infinite;
    -webkit-animation: loading 1s infinite steps(12);
  }

  .text {
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
  }
}

.loader-background {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bkg-overlay-color);
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
