<template>
  <div class="page">
    <loader :visible="loading" />
    <main-menu
      :title="$t('choose_a_folder')"
      :actionButtons="[
        {
          icon: 'icon-plus',
          clickCallback: createNewFolder,
          title: $t('create_a_new_folder'),
        },
      ]"
    ></main-menu>
    <div class="list">
      <ul>
        <folder-list-button
          v-for="folder in folders"
          :key="folder.id"
          :folder="folder"
          :clickCallback="selectFolder"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu.vue";
import Loader from "../components/Loader.vue";
import api from "../api";
import FolderListButton from "../components/FolderListButton.vue";

export default {
  name: "ListFolder",
  components: { MainMenu, Loader, FolderListButton },
  metaInfo() {
    return {
      title: this.$i18n.t("choose_a_folder"),
    };
  },
  data() {
    return {
      loading: false,
      folders: [],
    };
  },
  computed: {},
  methods: {
    createNewFolder() {
      this.$router.push({ name: "createFolder" });
    },
    selectFolder(e, folder) {
      this.$router.push({
        name: "folder",
        params: { id: folder.id },
        query: { token: folder.token },
      });
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const response = await api(this.$root.jwtToken).get(`/folder`);
      this.folders = response.data.folders;
    } catch (error) {
      this.$root.error = error.response;
    }
    this.loading = false;
  },
};
</script>

<style lang="less">
@import "../styles/list.less";
</style>
