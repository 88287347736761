<template>
  <div class="page">
    <h1>{{ $t("an_error_x_occured", { code: error.status }) }}</h1>
    <p>{{ error.data ? error.data.message : $t("unknown...") }}</p>
    <custom-button :button="button" />
  </div>
</template>

<script>
import CustomButton from "../components/CustomButton.vue";

export default {
  name: "Error",
  components: { CustomButton },
  metaInfo() {
    return {
      title: this.$i18n.t("an_error_x_occured", { code: this.error.status }),
    };
  },
  props: {
    error: Object,
  },
  computed: {
    button() {
      if (this.error.status === 401) {
        return {
          text: this.$i18n.t("log_in"),
          clickCallback: this.onLoginClicked,
        };
      }
      return {
        text: this.$i18n.t("come_back_to_homepage"),
        clickCallback: this.onHomeClicked,
      };
    },
  },
  methods: {
    onHomeClicked() {
      this.$router.push({ name: "home" });
    },
    onLoginClicked() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 10px;
}

h1 {
  font-size: 3em;
  line-height: 1em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
  line-height: 1.2em;
  margin-bottom: 10px;
}
</style>
