<template>
  <div class="menu">
    <div class="menu-content">
      <custom-button v-if="backButton" :button="backButton"></custom-button>
      <h1><span v-if="titleIcon" :class="titleIcon"></span> {{ title }}</h1>
      <span v-if="info" class="info"
        >{{ info }}
        <span v-if="infoDescription" class="text">{{
          infoDescription
        }}</span></span
      >
      <custom-button
        v-for="(actionButton, index) in actionButtons"
        :key="index"
        :button="actionButton"
      ></custom-button>
      <switch-button v-if="switchOptions" :options="switchOptions" />
      <custom-button
        v-for="(otherButton, index) in otherButtons"
        :key="index"
        :button="otherButton"
      ></custom-button>
    </div>
  </div>
</template>

<script>
import CustomButton from "./CustomButton.vue";
import SwitchButton from "./SwitchButton.vue";

export default {
  components: { CustomButton, SwitchButton },
  name: "MainMenu",
  props: {
    titleIcon: String,
    title: String,
    info: String,
    infoDescription: String,
    backButton: Object,
    actionButtons: Array,
    otherButtons: Array,
    switchOptions: Object,
  },
};
</script>

<style lang="less" scoped>
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--bkg-menu-color);
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .menu-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 10px;
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;
    min-height: calc(2.8em + 2 * 10px + 1px);
    min-height: calc(2.8em + 2 * 10px + 1px + env(safe-area-inset-top));

    @media screen and (min-width: 1200px) {
      width: var(--max-width2);
    }

    & > * {
      margin: 0 10px;
    }

    & > :first-child {
      margin-left: 0;
    }

    & > :last-child {
      margin-right: 0;
    }

    h1 {
      font-size: 1.4em;
      line-height: 1.4em;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0;
    }

    .button {
      margin: 10px 0;
    }

    .button + .button {
      margin-left: 10px;
    }

    .info {
      font-size: 1.2em;
      line-height: 1.2em;
      white-space: nowrap;
      font-weight: lighter;

      .text {
        font-size: 0.8em;
        line-height: 0.8em;
      }

      @media screen and (max-width: 600px) {
        text-align: center;

        .text {
          display: block;
        }
      }
    }
  }
}
</style>
