<template>
  <li>
    <span
      class="list-content list-button"
      @click="onClick"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <span v-if="!hover" class="icon-folder"></span>
      <span v-else class="icon-folder-open"></span>
      <span class="text">{{ folder.name }}</span>
    </span>
  </li>
</template>

<script>
export default {
  name: "FolderListButton",
  props: {
    folder: Object,
    clickCallback: Function,
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    onClick(e) {
      if (typeof this.clickCallback === "function") {
        this.clickCallback(e, this.folder);
      }
    },
  },
};
</script>
