<template>
  <div class="switch" :class="{ 'switch-on': options.state }" @click="onClick">
    <div v-if="options.textOff" class="text text-off">
      {{ options.textOff }}
    </div>
    <div class="switch-toggle">
      <div
        v-if="options.iconOn"
        class="switch-icon switch-icon-on"
        :class="options.iconOn"
      ></div>
      <div class="handle"></div>
      <div
        v-if="options.iconOff"
        class="switch-icon switch-icon-off"
        :class="options.iconOff"
      ></div>
    </div>
    <div v-if="options.textOn" class="text text-on">{{ options.textOn }}</div>
  </div>
</template>

<script>

export default {
  name: "SwitchButton",
  props: {
    options: {
      type: Object,
      default() {
        return {
          textOff: this.$this.t("off"),
          iconOff: undefined,
          textOn: this.$i18n.t("on"),
          iconOn: undefined,
          state: false,
          clickCallback: undefined,
        };
      },
    },
  },
  methods: {
    onClick(e) {
      this.options.state = !this.options.state;
      if (typeof this.options.clickCallback === "function") {
        this.options.clickCallback(e, this.options.state);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@switchWidth: 4em;
@switchHeight: 2em;
@switchBorderSize: 2px;

.switch {
  cursor: pointer;

  .text {
    display: inline-block;
    vertical-align: middle;
    font-size: 1em;
    line-height: @switchHeight;
    text-transform: uppercase;
  }
  .text-off {
    margin-right: 10px;
    color: var(--text-color);

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .text-on {
    margin-left: 10px;
    color: var(--text-light-color);

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  .switch-toggle {
    width: @switchWidth;
    height: @switchHeight;
    display: inline-block;
    vertical-align: middle;
    background-color: var(--tint-color);
    position: relative;
    border-radius: @switchHeight;

    .handle {
      height: calc(@switchHeight - 2 * @switchBorderSize);
      width: calc(@switchHeight - 2 * @switchBorderSize);
      border-radius: 50%;
      background-color: var(--switch-handle-color);
      position: absolute;
      top: @switchBorderSize;
      left: @switchBorderSize;
      transform: translateX(0);
      transition: transform 0.2s ease-in-out;
      z-index: 2;
    }

    .switch-icon {
      position: absolute;
      top: 0;
      height: @switchHeight;
      width: @switchHeight;
      font-size: @switchHeight / 2;
      line-height: @switchHeight;
      text-align: center;
      z-index: 1;
      color: rgba(255, 255, 255, 0.5);
    }
    .switch-icon-off {
      right: @switchBorderSize;
    }
    .switch-icon-on {
      left: @switchBorderSize;
    }
  }

  &:hover {
    .switch-toggle {
      .handle {
        background-color: var(--switch-handle-hover-color);
      }
    }
  }
}
.switch-on {
  .text-off {
    color: var(--text-light-color);
  }
  .text-on {
    color: var(--text-color);
  }
  .switch-toggle {
    background-color: var(--tint-color2);

    .handle {
      transform: translateX(calc(@switchWidth - @switchHeight));
    }
  }
}

@media screen and (max-width: 600px) {
  .text {
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .switch-icon {
    display: none;
  }
}
</style>
