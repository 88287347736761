import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import { DEFAULT_LANGUAGE, isLanguageAvailable } from "./i18n/languages";
import { isPrerender } from "./helpers/isPrerender";

Vue.config.productionTip = false;

/**
 * setup i18n locale from query parmeter "lang" only for prerender.
 */
router.beforeEach((to, from, next) => {
  const language = isPrerender() ? to.query.lang || i18n.locale : i18n.locale;
  i18n.locale = isLanguageAvailable(language) ? language : DEFAULT_LANGUAGE;
  // set correct query parameter from browser language
  if (to.query.lang !== i18n.locale) {
    router.replace({ ...to, query: { ...to.query, lang: i18n.locale } });
  }
  next();
});

new Vue({
  i18n,
  router,
  data() {
    return {
      error: undefined,
      mode: "upload",
      jwtToken: undefined,
    };
  },
  render: (h) => h(App),
}).$mount("#app");
