<template>
  <transition :name="transitionName" mode="out-in">
    <error v-if="error" :error="error" />
    <router-view v-else />
  </transition>
</template>

<script>
import Error from "./views/Error.vue";

export default {
  name: "App",
  components: { Error },
  metaInfo() {
    return {
      title: "Uploader",
      titleTemplate: "%s - Uploader",
      meta: [
        { name: "description", content: this.$i18n.t("meta_description") }
      ],
    };
  },
  data() {
    return {
      transitionName: "",
    };
  },
  computed: {
    error() {
      return this.$root.error;
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.$root.error = undefined;
      this.transitionName = to.name === "home" ? "slide-right" : "slide-left";
      next();
    });
  },
};
</script>

<style lang="less">
@import "styles/reset.css";
@import "styles/icomoon.css";
@import "styles/config.css";
@import "styles/main.less";
</style>
