import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/folder/:id",
    name: "folder",
    component: () => import("../views/Folder.vue"),
  },
  {
    path: "/create-folder",
    name: "createFolder",
    component: () => import("../views/CreateFolder.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
