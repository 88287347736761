export default class AnimatedNumber {
    /**
     * Animated Number constructor
     * @param {(number: number) => void} callback 
     */
    constructor(callback) {
        if (typeof callback != "function") {
            throw new Error("callback must be a function!");
        }

        this.number = 0;
        this.end = 0;
        this.step = 1;
        this.direction = 1;
        this.speed = 16;
        this.callback = callback;
        this.timer = null;
    }
    /**
     * Set start number
     * @param {number} from 
     */
    startFrom(from) {
        this.number = from;
    }
    /**
     * Animate number between start and end
     * @param {number} to 
     * @returns void
     */
    animate(to) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.number === to) {
            this.callback(this.number);
            return;
        }
        this.direction = this.number < to ? 1 : -1;
        this.end = to;
        this.callback(this.number);
        this.timer = setTimeout(this._calculate.bind(this), this.speed);
    }
    /**
     * Calculate next number to display and drive the animation
     * @private
     */
    _calculate() {
        this.number = this.number + this.step * this.direction;
        this.callback(this.number);
        if (Math.abs(this.end - this.number) > 0) {
            this.timer = setTimeout(this._calculate.bind(this), this.speed);
        }
    }
}