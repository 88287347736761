<template>
  <span
    @click="onClick"
    class="button"
    :class="cssClass"
    :title="button.title || ''"
  >
    <span v-if="button.icon" :class="button.icon"></span>
    <span v-if="button.text" class="text">{{ button.text }}</span>
  </span>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    button: {
      type: Object,
      default() {
        return {
          customClass: "",
          disabled: false,
          icon: undefined,
          text: undefined,
          title: undefined,
          clickCallback: undefined,
        };
      },
    },
  },
  methods: {
    onClick(e) {
      if (this.button.disabled === true) {
        return;
      }
      if (typeof this.button.clickCallback === "function") {
        this.button.clickCallback(e);
      }
    },
  },
  computed: {
    cssClass() {
      return [
        this.button.text ? "" : "compact",
        this.button.customClass || "",
        this.button.disabled ? "disabled" : "",
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  font-size: 1.4em;
  line-height: 2em;
  text-align: center;
  min-width: 2em;
  display: inline-block;
  height: 2em;
  color: var(--tint-color);
  border: 1px solid var(--border-color);
  background-color: var(--button-bkg-color);
  padding: 0 8px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: color 0.5s ease;

  &.small-button {
    border-color: transparent;
    background-color: transparent;
    font-size: 1em;

    &.margin-left-reduced {
      margin-left: -5px;
    }
  }

  &.secondary-button {
    color: var(--text-color);
  }

  &.disabled {
    color: var(--text-light-color);
    background-color: transparent;
    cursor: default;
  }

  &.compact {
    padding: 0;
  }

  span {
    padding: 0 2px;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    display: inline-block;

    & + .text {
      margin-left: 0.5em;
    }
  }

  .text {
    display: inline-block;
    font-weight: lighter;
  }

  &:not(.disabled):hover {
    background-color: var(--button-bkg-hover-color);
  }
}

.page-download {
  .button:not(.disabled) {
    color: var(--tint-color2);
  }
}
</style>
