import axios from "axios";
import config from "../config";

const api = (jwtToken) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (jwtToken) {
    headers["Authorization"] = "Bearer " + jwtToken;
  }
  return axios.create({
    baseURL: config.apiHost,
    headers,
  });
};

export default api;
